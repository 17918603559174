<template>
  <div v-loading="permissionsLoading">
    <div v-if="selectedEntityId">
      <el-row class="mb-1">
        <b>All permissions</b>
        <div class="float-right">
          <el-button size="mini" type="primary" @click="openNewPermissionModal">
            Add new permission
          </el-button>
        </div>
      </el-row>
      <el-row class="edit-permissions">
        <el-scrollbar wrap-style="max-height: 60vh;">
          <el-collapse accordion style="background: red">
            <el-collapse-item
              v-for="(item, index) of entityPermissions"
              :key="index + '_main'"
              :title="item.name"
              :name="index"
            >
              <template slot="title" style="border: 1px solid #e4e7ed">
                <div
                  class="mt-1"
                  v-if="item.type != 'PROFILE'"
                  style="width: 100%"
                >
                <el-tooltip :content="item.name">
                 <span style="padding : 0px !important"> {{ item.name | truncate(16,'...')}}</span>
                </el-tooltip>
                  <div style="float:  right !important ;margin-right: 90px;" class="d-flex">
                    <el-checkbox-group
                      v-model="entityPermissions[index]['permissions']"
                      @change="
                        (changedValue) =>
                          updateAllpermissions(changedValue, item)
                      "
                    >
                      <el-checkbox label="VIEW"></el-checkbox>
                      <el-checkbox label="ADD"></el-checkbox>
                      <el-checkbox label="EDIT"></el-checkbox>
                      <el-checkbox label="DELETE"></el-checkbox>
                      <el-checkbox label="IMPORT"></el-checkbox>
                      <el-checkbox label="EXPORT"></el-checkbox>
                   
                    </el-checkbox-group>
                    <el-link
                    style="float:  right !important "
                      class="mr-1 ml-1"
                      type="danger"
                      :underline="false"
                      @click="deletePermission(index)"
                      ><i class="el-icon-delete"></i>
                    </el-link>
                  </div>
                </div>
              </template>
              <el-row align="middle" justify="center">
                <el-col :span="24" >
                  <div>
                    <h4>Other permissions</h4>
                    <el-checkbox-group
                      v-model="entityPermissions[index]['permissions']"
                    >
                      <!-- <el-checkbox label="VIEW"></el-checkbox> -->
                      <el-checkbox label="BULK UPDATE"></el-checkbox>
                      <el-checkbox label="SEND NOTIFICATION"></el-checkbox>
                      <el-checkbox label="DOCUMENT"></el-checkbox>
                      <el-checkbox label="LAYOUT"></el-checkbox>
                      <el-checkbox label="LAYOUT OPTIONS"></el-checkbox>
                     
                    </el-checkbox-group>
                  </div>
                  </el-col>
                  <el-col :span="24" v-if="!addingFilesLoading">
                  <div>
                    <h4>File permissions</h4>
                    <el-checkbox-group
                      v-model="entityPermissions[index]['files']"
                    >
                      <!-- <el-checkbox label="VIEW"></el-checkbox> -->
                      <el-checkbox label="ADD"></el-checkbox>
                      <el-checkbox label="DOWNLOAD"></el-checkbox>
                      <el-checkbox label="DELETE"></el-checkbox>
                      <el-checkbox label="RENAME"></el-checkbox>
                      <el-checkbox label="SHARE"></el-checkbox>
                      <el-checkbox label="SETTINGS"></el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-col>
                <el-col :span="24" v-else v-loading="addingFilesLoading">
                  Loading files permissions
                </el-col>
                <el-col :span="24">
                  <el-table
                    v-if="item.templates"
                    :data="Object.keys(item.templates)"
                    style="width: 100%"
                    border
                  >
                    <el-table-column label="Template" min-width="300">
                      <template slot-scope="scope">
                        <span>{{ getTemplateLabel(scope.row) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      v-for="per in ['VIEW', 'ADD','EDIT', 'BULK UPDATE']"
                      :label="per"
                      :key="per + 'per'"
                      min-width="180"
                    >
                      <template slot-scope="scope">
                        <el-checkbox
                          v-if="item.type === 'PROFILE'"
                          :label="per"
                          v-model="
                            entityPermissions[index]['templates'][scope.row]
                          "
                          @change="
                            (changedValue) =>
                              updateProfilepermissions(
                                changedValue,
                                item,
                                scope.row
                              )
                          "
                          :disabled="
                            checkProfileDisabled(
                              entityPermissions[index]['templates'],
                              per,
                              entityPermissions[index]['templates'][scope.row]
                            )
                          "
                        ></el-checkbox>
                        <el-checkbox
                          v-else
                          :label="per"
                          v-model="
                            entityPermissions[index]['templates'][scope.row]
                          "
                          @change="
                            (changedValue) =>
                              updateChildpermissions(changedValue, item)
                          "
                          :disabled="
                            (entityPermissions[index] &&
                              entityPermissions[index]['permissions'] &&
                              entityPermissions[index]['permissions'].indexOf(
                                per
                              ) === -1) ||
                            checkDisabled(
                              entityPermissions[index]['templates'],
                              per,
                              entityPermissions[index]['templates'][scope.row],
                              item
                            ) ||
                            (per == 'ADD' && scope.$index == 0)
                          "
                        ></el-checkbox>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-col>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-scrollbar>
      </el-row>
      <el-row class="float-right mt-1 save-buttons">
        <el-button size="mini" @click="close"> Cancel </el-button>
        <el-button type="primary" size="mini" @click="onSave"> Save </el-button>
      </el-row>
    </div>
    <div v-else>Invalid action</div>
    <dialog-component
      :visible="showNewPermissionModal"
      :title="'Add new permission'"
      :containerWidth="'40%'"
      @before-close="closeNewPermissionModal"
    >
      <div class="Add-Permission">
        Relational entities
        <el-select
          placeholder="Select relational entities"
          v-model="relationalEntities"
          style="width: 100%"
          multiple
          collapse-tags
          class="mb-1"
          filterable
        >
          <el-option
            v-for="(en, index) in (this.getAllEntities.data || []).filter(
              (e) => relationshipEntities.indexOf(e._id) != -1
            )"
            :key="index + en._id"
            :label="en.name"
            :value="en._id"
            :disabled="checkRelationalExist(en._id, 'RELATIONAL')"
          >
          </el-option>
        </el-select>
        Other entities
        <el-select
          placeholder="Select other entities"
          v-model="newEntities"
          style="width: 100%"
          multiple
          collapse-tags
          filterable
          class="mb-1"
        >
          <el-option
            v-for="(en, index) in this.getAllEntities.data"
            :key="index + en._id"
            :label="en.name"
            :value="en._id"
            :disabled="checkRelationalExist(en._id, 'OTHER_ENTITY')"
          >
          </el-option>
        </el-select>
        Nested relational entities
        <el-select
          placeholder="Select relational entities"
          v-model="nestedRelationalEntities"
          style="width: 100%"
          multiple
          collapse-tags
          class="mb-1"
          filterable
        >
          <el-option
            v-for="(en, index) in nestedRelationshipEntities"
            :key="index + en.id"
            :label="en.relationship_title"
            :value="en.key"
            :disabled="checkNestedRelationalExist(en.key)"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeNewPermissionModal" size="mini"
          >Close</el-button
        >
        <el-button @click="AddNewPermissionModal" size="mini" type="primary"
          >Add</el-button
        >
      </span>
    </dialog-component>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { groupByKey } from "../../helpers/appHelper";
export default {
  props: ["entity_id", "selectedEntityId"],
  data() {
    return {
      permissionsLoading: false,
      activeName: "",
      profile_permissions: {},
      selectedEntity: null,
      permissions: {
        entity_id: "",
        profile_permissions: {},
        entities: {},
      },
      relationshipsData: [],
      entitiesData: [],
      entityPermissions: [],
      allEntityPermissions: {},
      showNewPermissionModal: false,
      newEntities: [],
      relationalEntities: [],
      relationshipEntities: [],
      nestedRelationalEntities: [],
      nestedRelationshipEntities: [],
      existedPermissions: null,
      addingFilesLoading: true
    };
  },
  computed: {
    ...mapGetters("entities", ["getEntityDataById", "getAllEntities"]),
    ...mapGetters("entityRelationships", ["getEntityRelationships", "getEntityNestedRelationships"]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("applicationUsersPermission", [
      "getCreatePermissionStatus",
      "getEntityPermission",
      "getUpdatePermissionStatus",
    ]),
  },
  async mounted() {
    if (this.selectedEntityId) {
      this.permissionsLoading = true;
      await Promise.all([
        this.$store.dispatch(
          "applicationUsersPermission/fetchPermissionsEntityById",
          { id: this.selectedEntityId }
        ),
        this.$store.dispatch("entities/fetchEntities", {
          get_all: true,
          populateData: true,
        }),
        this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
          entity_id: this.selectedEntityId,
        }),
        this.$store.dispatch("companyTemplates/getAllCompanyTemplates", {
          get_all: true,
          include_standard: true,
          populateData: true,
        }),
        this.$store.dispatch("entityRelationships/fetchEntityNestedRelationships", {
          entity_id: this.selectedEntityId,
          fetchAll: true
        }),
      ]);
      if (this.getAllEntities && this.getAllEntities.data) {
        if (this.getEntityPermission?._id) {
          this.existedPermissions = { ...this.getEntityPermission };
          if (this.existedPermissions.profile_permissions) {
            let currentEntity = this.getAllEntities.data.find(
              (e) => e._id == this.selectedEntityId
            );
            if (currentEntity && currentEntity.templates) {
              let permissions = this.removeNotExistedTemplates(this.existedPermissions.profile_permissions,currentEntity.templates);
              currentEntity.templates.forEach((te) => {
                if (
                  permissions &&
                  permissions.templates &&
                  !permissions.templates[te.template_id]
                ) {
                  this.$set(permissions.templates, te.template_id, []);
                }
              });
              this.entityPermissions.push(permissions);
            }
            // else {
            //  this.entityPermissions.push(
            //    this.existedPermissions.profile_permissions
            //   );
            // }
          }
          if (this.existedPermissions.relational_entities) {
            Object.keys(this.existedPermissions.relational_entities).forEach(
              (entity) => {
                let currentEntity = this.getAllEntities.data.find(
                  (e) => e._id == entity
                );
                if (currentEntity && currentEntity.templates) {
                  let permissions = this.removeNotExistedTemplates(this.existedPermissions.relational_entities[entity],currentEntity.templates);
                  currentEntity.templates.forEach((te) => {
                    if (
                      permissions &&
                      permissions.templates &&
                      !permissions.templates[te.template_id]
                    ) {
                      this.$set(permissions.templates, te.template_id, []);
                    }
                  });
                  this.entityPermissions.push(permissions);
                } 
                // else {
                //   this.entityPermissions.push(
                //     this.existedPermissions.relational_entities[entity]
                //   );
                // }
              }
            );
          }
          if (this.existedPermissions.other_entities) {
            Object.keys(this.existedPermissions.other_entities).forEach(
              (entity) => {
                let currentEntity = this.getAllEntities.data.find(
                  (e) => e._id == entity
                );
                if (currentEntity && currentEntity.templates) {
                   let permissions = this.removeNotExistedTemplates(this.existedPermissions.other_entities[entity],currentEntity.templates); 
                  currentEntity.templates.forEach((te) => {
                    if (
                      permissions &&
                      permissions.templates &&
                      !permissions.templates[te.template_id]
                    ) {
                      this.$set(permissions.templates, te.template_id, []);
                    }
                  });
                  this.entityPermissions.push(permissions);
                } 
                // else {
                //   this.entityPermissions.push(
                //     this.existedPermissions.other_entities[entity]
                //   );
                // }
              }
            );
          }
          if (this.existedPermissions.nested_relational_entities) {
            Object.keys(this.existedPermissions.nested_relational_entities).forEach(
              (entity) => {
                let currentEntity = this.getAllEntities.data.find(
                  (e) => e._id == entity.split('#')[1]
                );
                if (currentEntity && currentEntity.templates) {
                  let permissions = this.removeNotExistedTemplates(this.existedPermissions.nested_relational_entities[entity],currentEntity.templates);
                  currentEntity.templates.forEach((te) => {
                    if (
                      permissions &&
                      permissions.templates &&
                      !permissions.templates[te.template_id]
                    ) {
                      this.$set(permissions.templates, te.template_id, []);
                    }
                  });
                  this.entityPermissions.push(permissions);
                } 
                // else {
                //   this.entityPermissions.push(
                //     this.existedPermissions.nested_relational_entities[entity]
                //   );
                // }
              }
            );
          }
        }
        this.getAllEntities.data.forEach((entity) => {
          this.$set(this.allEntityPermissions, entity._id, {});
          let templatesIds = entity?.templates.flatMap((e) => e.template_id);
          let obj = {};
          templatesIds.forEach((te) => {
            if (entity._id == this.selectedEntityId) {
              this.$set(obj, te, ["ADD","VIEW", "EDIT","BULK UPDATE"]);
            }
            this.$set(this.allEntityPermissions[entity._id], te, [
              "ADD",
              "VIEW",
              "EDIT",
              "BULK UPDATE"
            ]);
          });
          if (entity._id == this.selectedEntityId && !this.existedPermissions) {
            this.entityPermissions.push({
              name: "Profile permissions",
              entity_id: this.selectedEntityId,
              templates: obj,
              type: "PROFILE",
            });
          }
        });
        this.setRelationshipPermissions();
      }
      this.permissionsLoading = false;
    }
  },
  methods: {
    removeNotExistedTemplates(permissions, templates){
      Object.keys(permissions.templates || {}).forEach(t => {
        let existed = (templates || []).find(e => t == e.template_id);
        if(!existed){
          delete permissions.templates[t];
        }
      })
      return permissions
    },
    close() {
      this.$emit("close");
    },
    checkProfileDisabled(templates, per, value) {
      if (templates && Object.keys(templates) && per == "VIEW") {
        let viewIndex = [];
        Object.keys(templates).forEach((te) => {
          if (templates[te].indexOf("VIEW") > -1) {
            viewIndex.push(templates[te].indexOf("VIEW"));
          }
        });
        if (viewIndex.length == 1 && value.length) {
          return true;
        }
      }
      return false;
    },
    prepareParams() {
      let profilePermissions = this.entityPermissions.find(
        (e) => e.entity_id == this.selectedEntityId && e.type == "PROFILE"
      );
      let relationalEntities = this.entityPermissions.filter(
        (e) => e.type == "RELATIONAL"
      );
      let nestedRelationalEntities = this.entityPermissions.filter(
        (e) => e.type == "NESTED_RELATIONAL"
      );
      let relationalEntitiesObj = {}, nestedRelationalEntitiesObj = {};
      let otherEntities = this.entityPermissions.filter(
        (e) => e.type == "OTHER_ENTITY"
      );
      let otherEntitiesObj = {};
      if (relationalEntities.length) {
        relationalEntitiesObj = groupByKey(relationalEntities, "entity_id");
      }
      if (nestedRelationalEntities.length) {
        nestedRelationalEntitiesObj = groupByKey(nestedRelationalEntities, "key");
      }
      if (otherEntities.length) {
        otherEntitiesObj = groupByKey(otherEntities, "entity_id");
      }
      return {
        entity_id: this.selectedEntityId,
        profile_permissions: profilePermissions,
        relational_entities: relationalEntitiesObj,
        other_entities: otherEntitiesObj,
        nested_relational_entities: nestedRelationalEntitiesObj
      };
    },
    async onSave() {
      let params = this.prepareParams();
      this.permissionsLoading = true;
      if (this.existedPermissions?._id) {
        params.id = this.existedPermissions._id;
        await this.$store.dispatch(
          "applicationUsersPermission/updateEntityPermissions",
          params
        );
        if (this.getUpdatePermissionStatus) {
          this.permissionsLoading = false;
          this.$notify({
            title: "Success",
            message: "Entity permissions updated successfully",
            type: "success",
          });
          this.$emit("close");
        } else {
          this.permissionsLoading = false;
          this.$notify({
            title: "Error",
            message: "Error while updating permissions",
            type: "error",
          });
        }
      } else {
        await this.$store.dispatch(
          "applicationUsersPermission/createEntityPermissions",
          params
        );
        if (this.getCreatePermissionStatus) {
          this.permissionsLoading = false;
          this.$notify({
            title: "Success",
            message: "Entity permissions updated successfully",
            type: "success",
          });
          this.$emit("close");
        } else {
          this.permissionsLoading = false;
          this.$notify({
            title: "Error",
            message: "Error while updating permissions",
            type: "error",
          });
        }
      }
    },
    checkDisabled(templates, permission, data) {
      if(permission == 'BULK UPDATE' || permission == 'ADD'){
        return false
      }
      let allIndex = [];
      Object.keys(templates).forEach((te) => {
        if (templates[te]) {
          let index = templates[te].indexOf(permission);
          if (index > -1) {
            allIndex.push(index);
          }
        }
      });
      if (allIndex && allIndex.length == 1) {
        if (data && data.indexOf(permission) == -1) {
          return false;
        }
        return true;
      }
      return false;
    },
    updateProfilepermissions(permission, item, key) {
      if (item && item?.templates && item.templates[key]) {
        if (item.templates[key].indexOf("VIEW") == -1) {
          item.templates[key] = [];
        }
      }
    },
    updateChildpermissions(permission, item) {
      if (item?.templates && item.type != "PROFILE") {
        let viewIndex = [];
        let editIndex = [];
        let bulkUpdateIndex = []
        let addIndex = []
        Object.keys(item.templates).forEach((te) => {
          if (item.templates[te]) {
            viewIndex.push(item.templates[te].indexOf("VIEW"));
            editIndex.push(item.templates[te].indexOf("EDIT"));
            bulkUpdateIndex.push(item.templates[te].indexOf("BULK UPDATE"));
            addIndex.push(item.templates[te].indexOf('ADD'))
          }
        });
        if (viewIndex.every((e) => e == -1 || e == "-1")) {
          let mainViewIndex = item.permissions.indexOf("VIEW");
          if (mainViewIndex > -1) {
            item.permissions.splice(mainViewIndex, 1);
          }
        }
        if (editIndex.every((e) => e == -1 || e == "-1")) {
          let editViewIndex = item.permissions.indexOf("EDIT");
          if (editViewIndex > -1) {
            item.permissions.splice(editViewIndex, 1);
          }
        }
        if (bulkUpdateIndex.every((e) => e == -1 || e == "-1")) {
          let mainBulkUpdateIndex = item.permissions.indexOf("BULK UPDATE");
          if (mainBulkUpdateIndex > -1) {
            item.permissions.splice(mainBulkUpdateIndex, 1);
          }
        }
        if (addIndex.every((e) => e == -1 || e == "-1")) {
          let mainAddIndex = item.permissions.indexOf("ADD");
          if (mainAddIndex > -1) {
            item.permissions.splice(mainAddIndex, 1);
          }
        }
      }
    },
    updateAllpermissions(permissions, item) {
      if (
        permissions.indexOf("VIEW") == -1 &&
        (permissions.indexOf("EDIT") != -1 || permissions.indexOf("ADD") != -1)
      ) {
        item.permissions = [...permissions, ...["VIEW"]];
        permissions = [...permissions, ...["VIEW"]];
      }
      if (permissions.indexOf("VIEW") == -1 && item?.templates) {
        Object.keys(item.templates).forEach((te) => {
          if (item.templates[te]) {
            let index = item.templates[te].indexOf("VIEW");
            if (index > -1) {
              item.templates[te].splice(index, 1);
            }
          }
        });
      }
      if (permissions.indexOf("EDIT") == -1 && item?.templates) {
        Object.keys(item.templates).forEach((te) => {
          if (item.templates[te]) {
            let index = item.templates[te].indexOf("EDIT");
            if (index > -1) {
              item.templates[te].splice(index, 1);
            }
          }
        });
      }
      if(permissions.indexOf("BULK UPDATE") == -1 && item?.templates) {
        Object.keys(item.templates).forEach((te) => {
          if(item.templates[te]){
            let index = item.templates[te].indexOf('BULK UPDATE');
            if(index > -1){
              item.templates[te].splice(index, 1);
            }
          }
        })
      }
      else{
        Object.keys(item.templates).forEach(te => {
          if(item.templates[te]){
            item.templates[te] = [...item.templates[te],...["BULK UPDATE"]]
          }
        })
      }
      if(permissions.indexOf("ADD") == -1 && item?.templates) {
        Object.keys(item.templates).forEach((te) => {
          if(item.templates[te]){
            let index = item.templates[te].indexOf('ADD');
            if(index > -1){
              item.templates[te].splice(index, 1);
            }
          }
        })
      }
      else{
        Object.keys(item.templates).forEach(te => {
          if(item.templates[te]){
            item.templates[te] = [...item.templates[te],...["ADD"]]
          }
        })
      }
      if (permissions.indexOf("VIEW") !== -1 && item?.templates) {
        let viewIndex = [];
        Object.keys(item.templates).forEach((te) => {
          if (item.templates[te]) {
            let index = item.templates[te].indexOf("VIEW");
            viewIndex.push(index);
          }
        });
        if (viewIndex.every((e) => e == -1 || e == "-1")) {
          Object.keys(item.templates).forEach((te) => {
            if (item.templates[te]) {
              item.templates[te] = [...item.templates[te], ...["VIEW"]];
            }
          });
        }
      }
      if (permissions.indexOf("EDIT") !== -1 && item?.templates) {
        let editIndex = [];
        Object.keys(item.templates).forEach((te) => {
          if (item.templates[te]) {
            let index = item.templates[te].indexOf("EDIT");
            editIndex.push(index);
          }
        });
        if (editIndex.every((e) => e == -1 || e == "-1")) {
          Object.keys(item.templates).forEach((te) => {
            if (item.templates[te]) {
              item.templates[te] = [...item.templates[te], ...["EDIT"]];
            }
          });
        }
      }
    },
    checkNestedRelationalExist(key){
      if (this.entityPermissions.length) {
        return this.entityPermissions.find(
          (e) => e.key == key && e.type == 'NESTED_RELATIONAL'
        )
          ? true
          : false;
      }
      return false;
    },
    checkRelationalExist(id, type) {
      if (this.entityPermissions.length) {
        return this.entityPermissions.find(
          (e) => e.entity_id == id && e.type == type
        )
          ? true
          : false;
      }
      return false;
    },
    deletePermission(index) {
      this.entityPermissions.splice(index, 1);
    },
    AddNewPermissionModal() {
      if (this.newEntities.length) {
        this.newEntities.forEach((el) => {
          let selectedEntity = this.getAllEntities.data.find(
            (e) => e._id == el
          );
          this.entityPermissions.push({
            entity_id: el,
            name: selectedEntity.name,
            templates: this.getEntityTemplatePermissions(el),
            permissions: ["ADD", "VIEW", "EDIT", "DELETE","IMPORT","EXPORT","BULK UPDATE","SEND NOTIFICATION","DOCUMENTS" ,"LAYOUT","LAYOUT OPTIONS"],
            type: "OTHER_ENTITY",
            files: ['VIEW']
          });
        });
      }
      if (this.relationalEntities.length) {
        this.relationalEntities.forEach((el) => {
          let selectedEntity = this.getAllEntities.data.find(
            (e) => e._id == el
          );
          this.entityPermissions.push({
            entity_id: el,
            name: "My " + selectedEntity.name,
            templates: this.getEntityTemplatePermissions(el),
            permissions: ["ADD", "VIEW", "EDIT", "DELETE","IMPORT","EXPORT","BULK UPDATE","SEND NOTIFICATION","LAYOUT","LAYOUT OPTIONS"],
            type: "RELATIONAL",
            files: ['VIEW']
          });
        });
      }
      if(this.nestedRelationalEntities.length){
        this.nestedRelationalEntities.forEach(el => {
          let relationship = this.nestedRelationshipEntities.find(e => e.key == el);
          this.entityPermissions.push({
            key: relationship.key,
            name: relationship.relationship_title,
            templates: this.getEntityTemplatePermissions(el.split('#')[1]),
            permissions: ["ADD", "VIEW", "EDIT", "DELETE","IMPORT","EXPORT","BULK UPDATE","SEND NOTIFICATION","LAYOUT","LAYOUT OPTIONS"],
            type: "NESTED_RELATIONAL",
            files: ['VIEW']
          });
        });
      }
      this.closeNewPermissionModal();
    },
    closeNewPermissionModal() {
      this.showNewPermissionModal = false;
      this.newEntities = [];
      this.relationalEntities = [];
      this.nestedRelationalEntities = [];
    },
    openNewPermissionModal() {
      this.showNewPermissionModal = true;
    },
    getTemplateLabel(tempId) {
      if (tempId && this.getAllCompanyTemplatesData?.data) {
        let selectedTemp = this.getAllCompanyTemplatesData.data.find(
          (e) => e._id == tempId
        );
        if (selectedTemp?.name) {
          return selectedTemp.name;
        }
      }
      return "";
    },
    getEntityTemplatePermissions(entityId) {
      if (entityId && this.allEntityPermissions[entityId]) {
        return this.allEntityPermissions[entityId];
      }
      return {};
    },
    addFilesPermissions(){
      this.addingFilesLoading=true;
      this.entityPermissions=this.entityPermissions.map(e=>{
        if((e.entity_id || e.type == 'NESTED_RELATIONAL') && !e.files){
          this.$set(e,'files',['VIEW']);
        }
        return e
      });
      setTimeout(()=>{
        this.addingFilesLoading=false;
      },3000)
    },
    setRelationshipPermissions() {
      this.getEntityRelationships.forEach((el) => {
        if (el?.child_entity_id !== this.selectedEntityId) {
          this.relationshipEntities.push(el.child_entity_id);
          if (!this.existedPermissions) {
            this.entityPermissions.push({
              entity_id: el?.child_entity_id,
              name: "My " + el?.child_relation_title,
              templates: this.getEntityTemplatePermissions(el?.child_entity_id),
              permissions: ["ADD", "VIEW", "EDIT", "DELETE","IMPORT","EXPORT","BULK UPDATE","SEND NOTIFICATION","LAYOUT","LAYOUT OPTIONS"],
              type: "RELATIONAL",
            });
          }
        } else if (this.selectedEntityId !== el?.parent_entity_id) {
          this.relationshipEntities.push(el.parent_entity_id);
          if (!this.existedPermissions) {
            this.entityPermissions.push({
              entity_id: el?.parent_entity_id,
              name: "My " + el?.parent_relation_title,
              templates: this.getEntityTemplatePermissions(
                el?.parent_entity_id
              ),
              permissions: ["ADD", "VIEW", "EDIT", "DELETE","IMPORT","EXPORT","BULK UPDATE","SEND NOTIFICATION"],
              type: "RELATIONAL",
            });
          }
        }
      });
      this.getEntityNestedRelationships.forEach((e) => {
        this.nestedRelationshipEntities.push({
          relationship_title: e.parent_relationship_details.relationship_title +"/" + e.relationship_title,
          key: e.parent_relationship_details.relationship_id + "#" + e.relationship_id,
          id: e._id
        })
      })
      this.addFilesPermissions();
    },
  },
};
</script>
<style>
.Add-Permission{
  margin: 20px;
}
.edit-permissions{
  width: 100% !important;
  margin-top: 30px;
}

.save-buttons{
  margin-top: 30px;
}

</style>
